import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type BlogTypographyProps = {
  variant?:
    | "bigTitle"
    | "title1"
    | "title2"
    | "subtitle1"
    | "subtitle2"
    | "overtitle1"
    | "overtitle2"
    | "body1"
    | "body2"
    | "body3"
    | "span";
} & Omit<TypographyProps, "variant" | "color">;

const BlogTypography = forwardRef<any, BlogTypographyProps>(
  ({ variant, sx, ...props }, ref) => {
    return (
      <Typography
        ref={ref}
        component={
          variant === "span"
            ? "span"
            : variant === "bigTitle"
            ? "h1"
            : variant === "title1"
            ? "h2"
            : variant === "title2"
            ? "h3"
            : variant === "overtitle1" || variant === "overtitle2"
            ? "h4"
            : "p"
        }
        sx={{
          color: "#000",
          ...(variant === "bigTitle"
            ? {
                fontSize: { xl: "96px", lg: "80px", md: "64px", xs: "40px" },
                lineHeight: { xl: "100px", lg: "84px", md: "68px", xs: "44px" },
                fontWeight: 700,
              }
            : variant === "overtitle1"
            ? {
                fontSize: { md: "21px", xs: "16px" },
                lineHeight: { md: "21px", xs: "20px" },
                fontWeight: 700,
                textTransform: "uppercase",
              }
            : variant === "overtitle2"
            ? {
                fontSize: { md: "20px", xs: "16px" },
                lineHeight: { md: "28px", xs: "18px" },
                fontWeight: 400,
                textTransform: "uppercase",
              }
            : variant === "title1"
            ? {
                fontSize: { lg: "64px", md: "52px", xs: "36px" },
                lineHeight: { lg: "68px", md: "56px", xs: "44px" },
                fontWeight: 700,
              }
            : variant === "title2"
            ? {
                fontSize: { md: "36px", xs: "24px" },
                lineHeight: { md: "44px", xs: "32px" },
                fontWeight: 700,
              }
            : variant === "subtitle1"
            ? {
                fontSize: { lg: "28px", md: "22px", xs: "16px" },
                lineHeight: { lg: "36px", md: "28px", xs: "20px" },
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
              }
            : variant === "subtitle2"
            ? {
                fontSize: { md: "16px", xs: "12px" },
                lineHeight: { md: "18px", xs: "14px" },
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
              }
            : variant === "body1"
            ? {
                fontSize: { sm: "24px", xs: "20px" },
                lineHeight: { sm: "32px", xs: "28px" },
                fontWeight: 400,
              }
            : variant === "body2"
            ? {
                fontSize: { md: "20px", xs: "14px" },
                lineHeight: { md: "28px", xs: "18px" },
                fontWeight: 400,
              }
            : variant === "body3"
            ? {
                fontSize: { sm: "14px", xs: "12px" },
                lineHeight: { sm: "18px", xs: "14px" },
                fontWeight: 400,
              }
            : variant === "span"
            ? {
                fontSize: "inherit",
                lineHeight: "inherit",
                fontWeight: "inherit",
                color: "inherit",
              }
            : {}),
          ...sx,
        }}
        {...props}
      />
    );
  }
);
export default BlogTypography;
