import { Box } from "@mui/material";
import React from "react";
import { l_blog } from "../../locales";
import BlogTypography from "../../theme/BlogTypography";
import Decorations from "./Decorations";

const Title = () => {
  return (
    <Box
      sx={{
        paddingTop: { xs: "56px", md: "200px" },
        paddingBottom: { xs: "48px", md: "120px" },
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        gap: { md: "32px", xs: "4px" },
      }}
    >
      <Box
        sx={{
          width: { md: "60%", xs: "90%" },
          maxWidth: { md: "700px", xs: "350px" },
        }}
      >
        <BlogTypography
          variant="overtitle1"
          sx={{ marginBottom: { md: "56px", xs: "16px" } }}
        >
          Blog
        </BlogTypography>
        <BlogTypography
          variant="bigTitle"
          sx={{ marginBottom: { md: "56px", xs: "16px" } }}
        >
          {l_blog("title.title1")}
          <br />
          {l_blog("title.title2")}
        </BlogTypography>
        <BlogTypography variant="subtitle1" sx={{}}>
          {l_blog("title.subtitle")}
        </BlogTypography>
      </Box>
      <Box
        sx={{
          // width: "40%",
          flex: 1,
          maxWidth: "700px",
        }}
      >
        <Decorations />
      </Box>
    </Box>
  );
};

export default Title;
