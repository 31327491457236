import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Decorations = () => {
  gsap.registerPlugin(ScrollTrigger);
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const animations = useRef<Tween[]>([]);

  useEffect(() => {
    animations.current.forEach((anim) => {
      anim.scrollTrigger?.kill();
      anim.kill();
    });

    animations.current[0] = gsap.fromTo(
      ".blog-title-orange-pill",
      {
        x: "50%",
      },
      {
        scrollTrigger: {
          trigger: ".blog-title-animation-container",
          scrub: 2,
          start: "top 10%",
        },
        x: 0,
      }
    );

    animations.current[1] = gsap.fromTo(
      ".blog-title-orange-circle",
      {
        x: "20%",
        y: isOverMd ? "-50%" : "50%",
      },
      {
        scrollTrigger: {
          trigger: ".blog-title-animation-container",
          scrub: 2,
          start: "top 10%",
        },
        x: 0,
        y: 0,
      }
    );

    animations.current[2] = gsap.fromTo(
      ".blog-title-blue-lemon",
      {
        x: "-50%",
        y: "10%",
        rotate: 137,
      },
      {
        scrollTrigger: {
          trigger: ".blog-title-animation-container",
          scrub: 2,
          start: "top 20%",
        },
        x: 0,
        y: 0,
        rotate: 58,
      }
    );
  }, [isOverMd]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        left: { xs: "-30%", sm: 0 },
        marginLeft: "auto",
        maxWidth: { xs: "unset", sm: "250px", md: "unset" },
      }}
      className="blog-title-animation-container"
    >
      <Box
        sx={{
          width: { lg: "220px", md: "160px", xs: "80px" },
          display: { sm: "block", xs: "none" },
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
        className="blog-title-orange-pill"
      >
        <StaticImage
          src={"../../images/orange-pill.svg"}
          alt={""}
          // layout={"constrained"}
          width={230}
        />
      </Box>
      <Box
        sx={{
          width: { lg: "105px", md: "75px", sm: "55px", xs: "42px" },
          position: "absolute",
          bottom: "50%",
          right: 0,
        }}
        className="blog-title-orange-circle"
      >
        <StaticImage
          src={"../../images/orange-circle.svg"}
          alt={""}
          width={105}
        />
      </Box>
      <Box
        sx={{
          width: { lg: "195px", md: "140px", sm: "70px", xs: "48px" },
          transform: "rotate(137deg)",
          position: "absolute",
          top: 0,
          right: "40%",
        }}
        className="blog-title-blue-lemon"
      >
        <StaticImage src={"../../images/blue-lemon.svg"} alt={""} width={200} />
      </Box>
    </Box>
  );
};

export default Decorations;
