import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      description:
        "Nel nostro blog puoi trovare alcuni esempi di temi che ci appassionano e scoprire di più su di noi tramite i nostri articoli.",
    },
    title: {
      title1: "Una passione",
      title2: "a tempo pieno",
      subtitle:
        "Amiamo quello che facciamo, per questo non possiamo fare a meno di parlarne",
    },
    articles: {
      articleGeneralDescription:
        "Scopri di più su questo tema leggendo l'articolo.",
      buttonTxt: "Scopri di più",
    },
  },
  en: {
    seo: {
      description:
        "On our blog you can find examples of what we are thrilled about and find out more on us through our articles.",
    },
    title: {
      title1: "A fulltime",
      title2: "passion",
      subtitle: "We love what we do, that's why we can't stop talking about it",
    },
    articles: {
      articleGeneralDescription:
        "Discover more on this topic by reading this article.",
      buttonTxt: "Find out more",
    },
  },
};

export const l_blog = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
