/** @jsxImportSource @emotion/react */
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import Img from "gatsby-image";
import { StaticImage } from "gatsby-plugin-image";
import { DateTime, Settings } from "luxon";
import { useState } from "react";
import { animated, useSpring } from "react-spring";
import { LocalizedLink } from "../../../../components/LocalizedLink";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { ContentfulArticle } from "../../../../templates/Article/Article";
import { l_blog } from "../../locales";
import BlogTypography from "../../theme/BlogTypography";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import ArticleChips from "./ArticleChips";

Settings.defaultLocale = "it-IT";
const AnimatedBox = animated(Box);

// interface Article {
//   node: {
//     id: string;
//     slug: string;
//     title: string;
//     subtitle: string;
//     isVisible: boolean;
//     updatedAt?: string;
//     publishedAt?: string;
//     category: {
//       title: string;
//       fullname: string;
//     };
//     foregroundImage: any;
//     author: {
//       name: string;
//       surname: string;
//       role: string;
//     };
//   };
// }

const ArticleInGrid = ({ article }: { article: ContentfulArticle }) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const formattedDate = DateTime.fromISO(article.publishedAt!).toFormat(
    "MMM dd, yyyy"
  );
  const [isHovered, setIsHovered] = useState(false);

  const [style] = useSpring(
    () => ({
      opacity: isHovered ? 1 : 0,
    }),
    [isHovered]
  );

  return (
    <Box
      onMouseOver={() => {
        if (isOverMd) setIsHovered(true);
      }}
      onMouseOut={() => setIsHovered(false)}
    >
      <LocalizedLink
        disableLink={isOverMd}
        to={`/blog/${article.category.title}/${article.slug}/`}
        style={{
          textDecoration: "none",
          width: "80%",
          maxWidth: { md: "250px", sm: "350px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            paddingTop: "65%",
            marginBottom: { md: "40px", xs: "16px" },
          }}
        >
          <AnimatedBox
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 3,
            }}
            style={{ ...style }}
          >
            <LocalizedLink
              disableLink={!isOverMd}
              to={`/blog/${article.category.title}/${article.slug}/`}
              style={{
                textDecoration: "none",
                width: "80%",
                maxWidth: { md: "250px", sm: "350px" },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: { md: "flex", xs: "none" },
                  width: "100%",
                  color: "white",
                  borderColor: "#ffffff",
                  backgroundColor: "transparent",
                  borderRadius: "56px",
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontWeight: 700,
                  padding: { lg: "24px", md: "24px", xs: "20px" },
                  borderWidth: "2px",
                  ":hover": {
                    borderColor: "#ffffff",
                    borderWidth: "2px",
                    backgroundColor: "rgba(255, 255, 255, 0.24)",
                  },
                }}
                startIcon={<ButtonDotIcon />}
              >
                {l_blog("articles.buttonTxt")}
              </Button>
            </LocalizedLink>
          </AnimatedBox>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              borderRadius: "36px",
              // transition: (theme) => theme.transitions.create("filter"),
              // ":hover": {
              //   filter: "grayscale(70%)",
              // },
            }}
          >
            <AnimatedBox
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: {
                  md: "rgba(0, 0, 0, 0.38)",
                  xs: "rgba(232, 83, 34, 0.38)",
                },
                zIndex: 2,
                borderRadius: "36px",
                // opacity: isHovered ? 1 : 0,
              }}
              style={{ ...style }}
            />
            <Img
              fluid={article.foregroundImage?.fluid}
              css={{
                borderRadius: 36,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ marginBottom: "16px" }}>
          <BlogTypography variant="body3" sx={{ color: "rgba(0,0,0, 0.6)" }}>
            <BlogTypography
              variant="span"
              sx={{
                textTransform: "uppercase",
                fontWeight: 700,
                color: "black",
              }}
            >
              {article.category.title}
            </BlogTypography>
            {" " + "-" + " "}
            {/*
            <BlogTypography variant="span" sx={{ textTransform: "capitalize" }}>
              {formattedDate}
            </BlogTypography>{" "}
            di */}
            {article.author.name} {article.author.surname}
          </BlogTypography>
        </Box>
        <Box>
          <BlogTypography
            variant="body1"
            sx={{ fontWeight: 700, marginBottom: "8px" }}
          >
            {article.title}
          </BlogTypography>
          <BlogTypography variant="body1" sx={{ color: "rgba(0, 0, 0, .74)" }}>
            {article.subtitle ?? l_blog("articles.articleGeneralDescription")}
          </BlogTypography>
        </Box>
        {!isOverMd && (
          <ButtonWithUnderline
            defaultUnderlined
            children={
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  marginBottom: "4px",
                  marginTop: "12px",
                }}
              >
                <BlogTypography variant="subtitle1" sx={{ color: "black" }}>
                  {l_blog("articles.buttonTxt")}
                </BlogTypography>
                <Box
                  sx={{
                    width: { md: "24px", xs: "20px" },
                    marginLeft: { md: "16px", xs: "8px" },
                    marginTop: "4px",
                  }}
                >
                  <StaticImage alt="" src="../../images/arrow-icon.svg" />
                </Box>
              </Box>
            }
          />
        )}
      </LocalizedLink>
    </Box>
  );
};

const ArticlesGrid = ({ articles }: { articles: ContentfulArticle[] }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [animationParent] = useAutoAnimate({
    easing: "ease-in-out",
    duration: 300,
  });

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        marginBottom: { md: "300px", xs: "150px" },
      }}
    >
      <ArticleChips
        chips={articles
          .map((a) => a.category.title)
          .reduce(
            (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
            [] as string[]
          )}
        onChange={setSelectedCategory}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
          rowGap: {
            xs: "80px",
            md: "70px",
            lg: "100px",
          },
          columnGap: {
            md: "50px",
            lg: "70px",
          },
        }}
        ref={animationParent}
      >
        {articles
          .filter(
            (article) =>
              selectedCategory === article.category.title ||
              selectedCategory === "all"
          )
          .map((article) => {
            return (
              <Box key={article.id + selectedCategory} sx={{}}>
                <ArticleInGrid article={article} />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ArticlesGrid;
