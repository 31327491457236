import { Box } from "@mui/material";
import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import { ContentfulArticle } from "../../templates/Article/Article";
import ArticlesGrid from "./components/ArticlesGrid/ArticlesGrid";
import Title from "./components/Title/Title";

const Blog = ({ articles }: { articles: ContentfulArticle[] }) => {
  return (
    <>
      <Title />
      <ArticlesGrid articles={articles} />
      <Footer />
    </>
  );
};

export default Blog;
